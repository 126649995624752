import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import navEN from '../languages/en/navEN';
import navVI from '../languages/vi/navVI';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard
//const Dashboard = React.lazy(() => import('../pages/dashboard'));
const Home = React.lazy(() => import('../pages/home'));
// // apps
// const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
// const EmailInbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
// const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
// const EmailCompose = React.lazy(() => import('../pages/apps/Email/Compose'));
// const ProjectList = React.lazy(() => import('../pages/apps/Project/List'));
// const ProjectDetail = React.lazy(() => import('../pages/apps/Project/Detail/'));
// const TaskList = React.lazy(() => import('../pages/apps/Tasks/List'));
// const TaskBoard = React.lazy(() => import('../pages/apps/Tasks/Board'));

// // pages
// const Starter = React.lazy(() => import('../pages/other/Starter'));
// const Profile = React.lazy(() => import('../pages/other/Profile/'));
// const Activity = React.lazy(() => import('../pages/other/Activity'));
// const Invoice = React.lazy(() => import('../pages/other/Invoice'));
// const Pricing = React.lazy(() => import('../pages/other/Pricing'));
// const Error404 = React.lazy(() => import('../pages/other/Error404'));
// const Error500 = React.lazy(() => import('../pages/other/Error500'));

// // ui
// const BSComponents = React.lazy(() => import('../pages/uikit/BSComponents/'));
// const FeatherIcons = React.lazy(() => import('../pages/uikit/Icons/Feather'));
// const UniconsIcons = React.lazy(() => import('../pages/uikit/Icons/Unicons'));
// const Widgets = React.lazy(() => import('../pages/uikit/Widgets/'));

// // charts
// const Charts = React.lazy(() => import('../pages/charts/'));

// // forms
// const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
// const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
// const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
// const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
// const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
// const Editor = React.lazy(() => import('../pages/forms/Editor'));

const user_role = getLoggedInUser();
const director_role = ['director'];
const logistic_role = ['admin', 'management', 'logistic'];
const action_role = ['admin', 'management'];
const users_team_role = ['admin', 'management', 'it_support', 'team_leader'];
const users_module_role = ['admin', 'management', 'manager', 'director', 'it_support', 'team_leader']; // 'team leader'
const campaigns_module_role = ['admin', 'management', 'manager', 'director'];
const ccms_module_role = ['admin', 'management', 'manager', 'director', 'team_leader',];

// // tables
// const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
// const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));

// offers
// const Offers = React.lazy(() => import('../pages/offers/offerDetail/Offers'));
// const OffersList = React.lazy(() => import('../pages/offers/offersList/OffersList'));
// const ApiHash = React.lazy(() => import('../pages/offers/apiHash/apiHash'));

// logistics

const Logistic = React.lazy(() => import('../pages/logistic/index'));
// const DOCrosscheck = React.lazy(() => import('../pages/logistic/doCrosscheck/doCrosscheck'));
const LogisticStatus = React.lazy(() => import('../pages/logistic/status/index'));
const LogisticLocation = React.lazy(() => import('../pages/logistic/location/index'));
const LogisticRescue = React.lazy(() => import('../pages/logistic/rescue/index'));
const LogisticSLA = React.lazy(() => import('../pages/logistic/slaConfig/index'));

//users

const UserManagement = React.lazy(() => import('../pages/users/management/management'));
const UserGroup = React.lazy(() => import('../pages/users/group/group'));
const UserTeam = React.lazy(() => import('../pages/users/team/team'));
const CampaignsOverview = React.lazy(() => import('../pages/campaigns/campaignsConfiguration/campaignsConfiguration'));
const CampaignsConfiguration = React.lazy(() => import('../pages/campaigns/campaignsOverallReport/campaignsOverallReport'));
const CreateNewCampaign = React.lazy(() => import('../pages/campaigns/createNewCampaign/createNewCampaign'));
const CampaignsManagement = React.lazy(() => import('../pages/campaigns/campaignsManagement/campaignsManagement'));
const CallingList = React.lazy(() => import('../pages/campaigns/callingList/callingList'));
const GeoLoadBalancing = React.lazy(() => import('../pages/campaigns/geoLoadBalancing/index'));

// Action Log
const ActionLog = React.lazy(() => import('../pages/action-log/actionlog'));

//CCMs
const AgentGroupCMMs = React.lazy(() => import('../pages/ccms/agentGroup/agentGroup'));
const AgentMonitorCMMs = React.lazy(() => import('../pages/ccms/agentMonitor/agentMonitor'));
const AgentSummaryCMMs = React.lazy(() => import('../pages/ccms/agentSummary/agentSummary'));
const Inbound = React.lazy(() => import('../pages/ccms/agentSummary/inbound/inbound'));
const DashboardCMMs = React.lazy(() => import('../pages/ccms/dashboard/dashboard'));
const InboundDashboard = React.lazy(() => import('../pages/ccms/dashboard/inboundDashboard/inboundDashboard'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.roles[0].name) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
    route: PrivateRoute,
};

// dashboards
// const dashboardRoutes = {
//     path: '/dashboard',
//     name: 'Dashboard',
//     icon: FeatherIcon.Home,
//     header: 'Navigation',
//     badge: {
//         variant: 'success',
//         text: '1',
//     },
//     component: Dashboard,
//     //roles: ['Admin'],
//     route: PrivateRoute
// };

const homeRoutes = {
    path: '/home',
    // name: 'Home',
    // icon: FeatherIcon.Home,
    header: 'Home',
    // badge: {
    //     variant: 'success',
    //     text: '1',
    // },
    component: Home,
    route: PrivateRoute
};

// // apps

// const calendarAppRoutes = {
//     path: '/apps/calendar',
//     name: 'Calendar',
//     header: 'Apps',
//     icon: FeatherIcon.Calendar,
//     component: CalendarApp,
//     route: PrivateRoute,
//     roles: ['Admin'],
// };

// const emailAppRoutes = {
//     path: '/apps/email',
//     name: 'Email',
//     icon: FeatherIcon.Inbox,
//     children: [
//         {
//             path: '/apps/email/inbox',
//             name: 'Inbox',
//             component: EmailInbox,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/apps/email/details',
//             name: 'Details',
//             component: EmailDetail,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/apps/email/compose',
//             name: 'Compose',
//             component: EmailCompose,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//     ]
// };

// const projectAppRoutes = {
//     path: '/apps/projects',
//     name: 'Projects',
//     icon: FeatherIcon.Briefcase,
//     children: [
//         {
//             path: '/apps/projects/list',
//             name: 'List',
//             component: ProjectList,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/apps/projects/detail',
//             name: 'Detail',
//             component: ProjectDetail,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//     ]
// };

// const taskAppRoutes = {
//     path: '/apps/tasks',
//     name: 'Tasks',
//     icon: FeatherIcon.Bookmark,
//     children: [
//         {
//             path: '/apps/tasks/list',
//             name: 'List',
//             component: TaskList,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/apps/tasks/board',
//             name: 'Board',
//             component: TaskBoard,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//     ],
// };

// const appRoutes = [calendarAppRoutes, emailAppRoutes, projectAppRoutes, taskAppRoutes];



// // pages
// const pagesRoutes = {
//     path: '/pages',
//     name: 'Pages',
//     header: 'Custom',
//     icon: FeatherIcon.FileText,
//     children: [
//         {
//             path: '/pages/starter',
//             name: 'Starter',
//             component: Starter,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/pages/profile',
//             name: 'Profile',
//             component: Profile,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/pages/activity',
//             name: 'Activity',
//             component: Activity,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/pages/invoice',
//             name: 'Invoice',
//             component: Invoice,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/pages/pricing',
//             name: 'Pricing',
//             component: Pricing,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/pages/error-404',
//             name: 'Error 404',
//             component: Error404,
//             route: Route
//         },
//         {
//             path: '/pages/error-500',
//             name: 'Error 500',
//             component: Error500,
//             route: Route
//         },
//     ]
// };


// // components
// const componentsRoutes = {
//     path: '/ui',
//     name: 'UI Elements',
//     header: 'Components',
//     icon: FeatherIcon.Package,
//     children: [
//         {
//             path: '/ui/bscomponents',
//             name: 'Bootstrap UI',
//             component: BSComponents,
//             route: PrivateRoute,
//             roles: ['management'],
//         },
//         {
//             path: '/ui/icons',
//             name: 'Icons',
//             children: [
//                 {
//                     path: '/ui/icons/feather',
//                     name: 'Feather Icons',
//                     component: FeatherIcons,
//                     route: PrivateRoute,
//                     roles: ['management'],
//                 },
//                 {
//                     path: '/ui/icons/unicons',
//                     name: 'Unicons Icons',
//                     component: UniconsIcons,
//                     route: PrivateRoute,
//                     roles: ['management'],
//                 },
//             ]
//         },
//         {
//             path: '/ui/widgets',
//             name: 'Widgets',
//             component: Widgets,
//             route: PrivateRoute,
//             roles: ['management'],
//         },

//     ]
// };

// // charts
// const chartRoutes = {
//     path: '/charts',
//     name: 'Charts',
//     component: Charts,
//     icon: FeatherIcon.PieChart,
//     roles: ['Admin'],
//     route: PrivateRoute
// }


// // forms
// const formsRoutes = {
//     path: '/forms',
//     name: 'Forms',
//     icon: FeatherIcon.FileText,
//     children: [
//         {
//             path: '/forms/basic',
//             name: 'Basic Elements',
//             component: BasicForms,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/advanced',
//             name: 'Advanced',
//             component: FormAdvanced,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/validation',
//             name: 'Validation',
//             component: FormValidation,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/wizard',
//             name: 'Wizard',
//             component: FormWizard,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/editor',
//             name: 'Editor',
//             component: Editor,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/upload',
//             name: 'File Upload',
//             component: FileUpload,
//             route: PrivateRoute,
//         }
//     ]
// };


// const tableRoutes = {
//     path: '/tables',
//     name: 'Tables',
//     icon: FeatherIcon.Grid,
//     children: [
//         {
//             path: '/tables/basic',
//             name: 'Basic',
//             component: BasicTables,
//             route: PrivateRoute,
//         },
//         {
//             path: '/tables/advanced',
//             name: 'Advanced',
//             component: AdvancedTables,
//             route: PrivateRoute,
//         }]
// };


// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.LOGIN : navVI.LOGIN,
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.LOGOUT : navVI.LOGOUT,
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.REGISTER : navVI.REGISTER,
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.CONFIRM : navVI.CONFIRM,
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.FORGET_PASS : navVI.FORGET_PASS,
            component: ForgetPassword,
            route: Route,
        },
    ],
};

// const offerProRoutes = {
//     path: '/adroute',
//     name: 'Offers',
//     icon: FeatherIcon.Link2,
//     children: [
//         {
//             path: '/adroute/offers',
//             name: 'Offers List',
//             component: OffersList,
//             route: PrivateRoute,
//         },
//         {
//             path: '/adroute/offer/:id',
//             name: 'Offer Detail',
//             component: Offers,
//             route: PrivateRoute,
//             hidden: true
//         },
//         {
//             path: '/adroute/apihash',
//             name: 'Api Hash',
//             component: ApiHash,
//             route: PrivateRoute
//         }
//     ]
// };

const rootUsersRoutes = {
    path: '/users',
    exact: true,
    component: () => <Redirect to="/users/management" />,
    route: PrivateRoute,
};

// const logisticRoutes = {
//     // path: '/logistic',
//     name: 'Logistic Management',
//     icon: FeatherIcon.Truck,
//     // component: Logistic,
//     roles: ['management', 'admin', 'logistic', 'manager'],
//     hidden: user_role ? logistic_role.indexOf(user_role.roles[0].name) === -1 ? true : false : false,
//     route: PrivateRoute,
//     children: [
//         {
//             path: '/logistic',
//             name: 'Logistic Management',
//             component: Logistic,
//             route: PrivateRoute,
//         },
//         {
//             path: '/logistic/docrosscheck',
//             name: 'DO Crosscheck',
//             component: DOCrosscheck,
//             route: PrivateRoute,
//         }
//     ]
// };

const logisticRoutes = {
    path: '/logistic',
    name: localStorage.getItem("i18nextLng") === "en" ? navEN.LOGISTIC.TITLE : navVI.LOGISTIC.TITLE,
    icon: FeatherIcon.Truck,
    roles: ['management', 'admin', 'logistic', 'manager'],
    hidden: user_role ? logistic_role.indexOf(user_role.roles[0].name) === -1 ? true : false : false,
    route: PrivateRoute,
    children: [
        {
            path: '/logistic/management',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.LOGISTIC.LOGISTIC_MGMT : navVI.LOGISTIC.LOGISTIC_MGMT,
            component: Logistic,
            route: PrivateRoute,
        },
        {
            path: '/logistic/status',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.LOGISTIC.STATUS : navVI.LOGISTIC.STATUS,
            component: LogisticStatus,
            roles: ['management', 'admin', 'logistic', 'manager'],
            route: PrivateRoute,
        },
        {
            path: '/logistic/location',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.LOGISTIC.LOCATION : navVI.LOGISTIC.LOCATION,
            component: LogisticLocation,
            roles: ['management', 'admin', 'logistic', 'manager'],
            route: PrivateRoute,
        },
        {
            path: '/logistic/rescue',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.LOGISTIC.RESCUE : navVI.LOGISTIC.RESCUE,
            component: LogisticRescue,
            roles: ['management', 'admin', 'logistic', 'manager'],
            route: PrivateRoute,
        },
        // {
        //     path: '/logistic/docrosscheck',
        //     name: localStorage.getItem("i18nextLng") === "en" ? navEN.LOGISTIC.DO : navVI.LOGISTIC.DO,
        //     component: DOCrosscheck,
        //     roles: ['management', 'admin', 'logistic', 'manager'],
        //     route: PrivateRoute,
        // },
        {
            path: '/logistic/sla',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.LOGISTIC.SLA : navVI.LOGISTIC.SLA,
            component: LogisticSLA,
            roles: ['management', 'admin', 'logistic', 'manager'],
            route: PrivateRoute,
        },
    ]
};

const campaignsRoutes = {
    path: '/campaigns',
    name: localStorage.getItem("i18nextLng") === "en" ? navEN.CAMPAIGN.TITLE : navVI.CAMPAIGN.TITLE,
    icon: FeatherIcon.TrendingUp,
    roles: ['management', 'admin', 'manager', 'director'],
    hidden: user_role ? campaigns_module_role.indexOf(user_role.roles[0].name) === -1 ? true : false : false,
    route: PrivateRoute,
    children: [
        {
            path: '/campaigns/list',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.CAMPAIGN.CP_LIST : navVI.CAMPAIGN.CP_LIST,
            component: CampaignsManagement,
            route: PrivateRoute,
        },
        {
            path: '/campaigns/callinglist',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.CAMPAIGN.CALLING_LIST : navVI.CAMPAIGN.CALLING_LIST,
            component: CallingList,
            route: PrivateRoute,
        },
        {
            path: '/campaigns/configuration',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.CAMPAIGN.CP_CONFIGURATION : navVI.CAMPAIGN.CP_CONFIGURATION,
            component: CampaignsConfiguration,
            route: PrivateRoute,
        },
        {
            path: '/campaigns/overview/list',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.CAMPAIGN.AG_CP : navVI.CAMPAIGN.AG_CP,
            component: CampaignsOverview,
            route: PrivateRoute,
        },
        {
            path: '/campaigns/geo-balancing',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.CAMPAIGN.GEO_BALANCING : navVI.CAMPAIGN.GEO_BALANCING,
            component: GeoLoadBalancing,
            route: PrivateRoute,
            hidden: [4, 5, 6, 15, 16].includes(user_role?.orgId) && director_role.includes(user_role?.roles[0].name) ? false : true
        },
        {
            path: '/campaigns/overview/listcp/:id/:name',
            name: 'Agents in Campaign',
            component: CampaignsOverview,
            route: PrivateRoute,
            hidden: true
        },
        {
            path: '/campaigns/overview/listcp/:id',
            name: 'Agents in Campaign',
            component: CampaignsOverview,
            route: PrivateRoute,
            hidden: true
        },
        {
            path: '/campaigns/createNewCampaign',
            name: 'Create New Campaign',
            component: CreateNewCampaign,
            route: PrivateRoute,
            hidden: true
        },
    ]
};

const usersRoutes = {
    path: '/users',
    name: localStorage.getItem("i18nextLng") === "en" ? navEN.USER.TITLE : navVI.USER.TITLE,
    icon: FeatherIcon.User,
    roles: ['management', 'admin', 'manager', 'director', 'it_support', 'team_leader'],
    hidden: user_role ? users_module_role.indexOf(user_role.roles[0].name) === -1 ? true : false : false,
    route: PrivateRoute,
    children: [
        {
            path: '/users/management',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.USER.USERS : navVI.USER.USERS,
            component: UserManagement,
            roles: ['management', 'admin', 'manager', 'director', 'it_support', 'team_leader'],
            route: PrivateRoute,
        },
        {
            path: '/users/group/a',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.USER.GROUP : navVI.USER.GROUP,
            component: UserGroup,
            route: PrivateRoute,
        },
        {
            path: '/users/group/ab/:id',
            name: 'Group',
            component: UserGroup,
            route: PrivateRoute,
            hidden: true
        },
        {
            path: '/users/team/a',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.USER.TEAM : navVI.USER.TEAM,
            component: UserTeam,
            roles: ['management', 'admin', 'manager', 'it_support', 'team_leader'],
            hidden: user_role ? users_team_role.indexOf(user_role.roles[0].name) === -1 ? true : false : false,
            route: PrivateRoute
        },
        {
            path: '/users/team/ab/:id',
            name: 'Team',
            component: UserTeam,
            route: PrivateRoute,
            hidden: true
        }
    ]
}

const ccmsRoutes = {
    path: '/ccms',
    name: localStorage.getItem("i18nextLng") === "en" ? navEN.CCMS.TITLE : navVI.CCMS.TITLE,
    icon: FeatherIcon.Aperture,
    roles: ['management', 'admin', 'manager', 'director', 'team_leader'],
    hidden: user_role ? ccms_module_role.indexOf(user_role.roles[0].name) === -1 ? true : false : false,
    // header: localStorage.getItem("i18nextLng") === "en" ? navEN.NAVI : navVI.NAVI,
    route: PrivateRoute,
    children: [
        {
            path: '/ccms/outbound-dashboard',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.CCMS.OUTBOUND_DASH : navVI.CCMS.OUTBOUND_DASH,
            component: DashboardCMMs,
            roles: ['management', 'admin', 'manager', 'director', 'team_leader'],
            // hidden: user_role ? ccms_module_role.indexOf(user_role.roles[0].name) === 5 ? true : false : false,
            route: PrivateRoute,
        },
        {
            path: '/ccms/inbound-dashboard',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.CCMS.INBOUND_DASH : navVI.CCMS.INBOUND_DASH,
            component: InboundDashboard,
            roles: ['management', 'admin', 'manager', 'director', 'team_leader'],
            // hidden: user_role ? ccms_module_role.indexOf(user_role.roles[0].name) === 5 ? true : false : false,
            route: PrivateRoute,
        },
        {
            path: '/ccms/agent-group',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.CCMS.GROUP_AGENTS : navVI.CCMS.GROUP_AGENTS,
            component: AgentGroupCMMs,
            roles: ['management', 'admin', 'manager', 'director', 'team_leader'],
            // hidden: user_role ? ccms_module_role.indexOf(user_role.roles[0].name) === 5 ? true : false : false,
            route: PrivateRoute,
        },
        {
            path: '/ccms/agent-monitor',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.CCMS.AGENTS_MONITOR : navVI.CCMS.AGENTS_MONITOR,
            component: AgentMonitorCMMs,
            roles: ['management', 'admin', 'manager', 'director', 'team_leader'],
            // hidden: user_role ? ccms_module_role.indexOf(user_role.roles[0].name) === 5 ? true : false : false,
            route: PrivateRoute,
        },
        {
            path: '/ccms/agent-summaries',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.CCMS.AGENT_SUMMARIES : navVI.CCMS.AGENT_SUMMARIES,
            component: AgentSummaryCMMs,
            roles: ['management', 'admin', 'manager', 'director', 'team_leader'],
            route: PrivateRoute,
        },
        {
            path: '/ccms/inbound-summaries',
            name: localStorage.getItem("i18nextLng") === "en" ? navEN.CCMS.INBOUND_SUMMARIES : navVI.CCMS.INBOUND_SUMMARIES,
            component: Inbound,
            roles: ['management','manager', 'team_leader'],
            // hidden: user_role ? ccms_module_role.indexOf(user_role.roles[0].name) === 5 ? true : false : false,
            route: PrivateRoute,
        }
    ]
}

const actionLogRoutes = {
    path: '/action-log',
    name: localStorage.getItem("i18nextLng") === "en" ? navEN.ACTION_LOG.TITLE : navVI.ACTION_LOG.TITLE,
    icon: FeatherIcon.Clipboard,
    component: ActionLog,
    roles: ['admin', 'management'],
    hidden: user_role ? action_role.indexOf(user_role.roles[0].name) === -1 ? true : false : false,
    route: PrivateRoute,
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    //dashboardRoutes,
    homeRoutes,
    //    ...appRoutes,
    //    pagesRoutes,
    //componentsRoutes,
    //     chartRoutes,
    // formsRoutes,
    // tableRoutes,
    authRoutes,
    ccmsRoutes,
    //offerProRoutes,
    logisticRoutes,
    usersRoutes,
    rootUsersRoutes,
    campaignsRoutes,
    actionLogRoutes
];

const authProtectedRoutes = [homeRoutes,ccmsRoutes, logisticRoutes, usersRoutes, campaignsRoutes, actionLogRoutes]; //dashboardRoutes,homeRoutes, ...appRoutes, pagesRoutes, componentsRoutes, chartRoutes, formsRoutes, tableRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
